import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Label, Radio } from "theme-ui"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default ( props ) => {

  const data = useStaticQuery(
    graphql`
      query FilterTagsRadio {
        allWordpressWpTagsMap {
          edges {
            node {
              name
              wordpress_id
            }
          }
        }
      }
    `
  )

  // todos los terms de la tax tags_map en un array
  // consulta estatica de arriba
  const allTermsTaxTagsMapAsArray = data.allWordpressWpTagsMap.edges

  return (
    <Box
      as={`div`}
      className='filter-ambits-radio'
      sx={{
        display: [`none`, `none`, `none`, `inherit`, `inherit`],
        mt: 3,
      }}
    >
      <Box
        as={`div`}
      >
        {
          allTermsTaxTagsMapAsArray.map(({ node, i }) => (
            <Label key={node.wordpress_id}>
              <Radio
                name='ambit'
                onClick={ function(){
                  return props.setHiddenFromMainComponent(

                    // allItemsAsArrayFromMainComponent viene del componente superior
                    // allItemsAsArrayFromMainComponent es filtrado
                    props.allItemsAsArrayFromMainComponent.filter(function(itemFiltered){

                      // Almaceno la id del term de la tax.
                      // Devuelve integer
                      // Esto viene de la static query de arriba
                      let id=node.wordpress_id

                      // Almaceno en un array las ids de todos los terminos
                      // Devuelve array
                      // itemFiltered.node.tags_map viene del componente superior
                      let arrayAllIdsTermsSingleCPT = itemFiltered.node.tags_map.map( e =>
                        e.wordpress_id
                      )

                      // Retorno un array que incluye los cpts que tienen la id del term de la tax.
                      return arrayAllIdsTermsSingleCPT.includes(id)

                    })
                  )
                } }
              />
              {node.name}
            </Label>
          ))
        }
        <Label>
          <Radio
            name='ambit'
            defaultChecked={true}
            onClick={ () => props.setHiddenFromMainComponent(props.allItemsAsArrayFromMainComponent) }
          />
          Tots els àmbits
        </Label>
      </Box>
    </Box>
  )
}
